import '../stylesheets/external'

import 'jquery'
import 'popper.js'
import 'bootstrap'

//theme
import '../javascripts/vendor/hs.megamenu'
import '../javascripts/vendor/fancybox'
import 'slick-carousel'
import 'malihu-custom-scrollbar-plugin'

import '../javascripts/theme/hs.core'
import '../javascripts/theme/hs.header'
import '../javascripts/theme/hs.unfold'
import '../javascripts/theme/hs.validation'

import '../javascripts/theme/hs.malihu-scrollbar'
import '../javascripts/theme/hs.modal-window'
import '../javascripts/theme/hs.show-animation'
import '../javascripts/theme/hs.slick-carousel'
import '../javascripts/theme/hs.go-to'
import '../javascripts/theme/hs.focus-state'
import '../javascripts/theme/hs.fancybox'
import '../javascripts/external_init'
import '../javascripts/styxies/main_page'

const images = require.context('../images', true)

