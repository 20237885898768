import showFlashes from 'javascripts/common/flashes'
import initMaskedInputs from 'javascripts/common/masked_input'

require('@rails/ujs').start()
require('turbolinks').start()
require('javascripts/channels')

$(document).on('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover({ trigger: 'hover' })

  showFlashes()
  initMaskedInputs()

  $('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 991,
    hideTimeOut: 0
  })

  $.HSCore.components.HSHeader.init($('#header'))

  $.HSCore.components.HSUnfold.init($('[data-unfold-target]'), {
    afterOpen() {
      if (!$('body').hasClass('IE11')) {
        $(this).find('input[type="search"]').focus()
      }
    }
  })

  $.HSCore.helpers.HSFocusState.init()

  $.HSCore.components.HSMalihuScrollBar.init($('.js-scrollbar'))

  $.HSCore.components.HSModalWindow.init('[data-modal-target]', '.js-signup-modal', {
    autonomous: true
  })

  $.HSCore.components.HSGoTo.init('.js-go-to')
})
